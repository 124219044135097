// extracted by mini-css-extract-plugin
export var Bold = "HistoireEast-module--Bold--uiZ7s";
export var ContactLink = "HistoireEast-module--ContactLink--Ldf6k";
export var Contactlink = "HistoireEast-module--Contactlink--9PCny";
export var DevelopmentWrapper = "HistoireEast-module--DevelopmentWrapper--tFiUp";
export var LegalMentionsWrapper = "HistoireEast-module--LegalMentionsWrapper--oFt0C";
export var Link = "HistoireEast-module--Link--0IYP0";
export var NameValueWrapper = "HistoireEast-module--NameValueWrapper--X5Gi1";
export var RowWrapper = "HistoireEast-module--RowWrapper--cVePP";
export var Wrapper = "HistoireEast-module--Wrapper--qN0a8";
export var link = "HistoireEast-module--link--Rt1Zb";